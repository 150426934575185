/* Copyright © 2019 Atomic.io Limited. All rights reserved. */
@font-face {
  font-family: 'Inter';
  src: url('Inter-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Mono';
  src: url('SF-Mono-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Mono';
  src: url('SF-Mono-Bold.otf') format('opentype');
  font-weight: bold;
}

:root {
  --atomic-dark-blue: #00143C;
  --atomic-light-blue: #1E6EFA;
  --atomic-text-blue: #0078C8;
  --atomic-red: #FF3C5A;
  --atomic-pink: #FFC5CE;
  --atomic-light-grey: #F2F2F3;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}

a {
  cursor: pointer;
  color: black;
}

body,
html {
  width: 100%;
  height: 100%;
}

body {
  background: white;
  letter-spacing: -0.02em;
}

body,
input,
button {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, Roboto, Segoe UI,
    Arial, sans-serif;
  font-size: 16.5px;
  letter-spacing: -0.02em;
  outline: none;
  transition: all 0.1s ease;
}

nav {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  position: relative;
  justify-content: space-between;
}

.nav-hidden {
  justify-content: flex-end;
  background-color: transparent;
  border: none;
  height: 0;
  padding: 0;
}

.input-field, .list-field {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0;
  padding: 0 20px;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  max-width: 400px;
  position: relative;
  min-height: 60px;
  transition: all 0.1s ease;
  cursor: text;
}

.list-field {
  padding-bottom: 10px;
}

.input-field:hover,
.input-field:focus-within {
  background: rgba(0, 0, 0, 0.1);
}

.input-field:active {
  background: rgba(0, 0, 0, 0.15);
}

.input-field .fake-label, .list-field .fake-label {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 20px;
  user-select: none;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease;
  pointer-events: none;
}

.list-field .fake-label {
  padding-top: 15px;
  font-size: 14.5px;
}

.input-field > input + .fake-label {
  padding-top: 20px;
  font-size: 16.5px;
}

.input-field > input:focus + .fake-label,
.input-field > input:not(:placeholder-shown) + .fake-label {
  padding-top: 10px;
  font-size: 14.5px;
}

/* These need to be separate from the above */
.input-field > input:not(:-ms-input-placeholder) + .fake-label {
  padding-top: 10px;
  font-size: 14.5px;
}

.input-field input {
  border: 0;
  background: transparent;
  font-size: 16.5px;
  margin: 30px 0 10px;
  line-height: 20px;
  padding: 0;
  transition: all 0.1s ease;
}

.list-field select {
  margin: 40px 0 10px;
}

.checkbox-field {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  text-align: left;
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border-radius: 5px;
}

.checkbox-field label {
  text-align: left;
  width: 100%;
}

.checkbox-field input {
  opacity: 0;
}

.checkbox-field .checkbox-icon  {
  width: 20px;
  height: 20px;
  opacity: 1;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transition: all 0.3s ease;
  background: white;
}

.checkbox-field .checkbox-icon:hover {
  background: rgba(0, 0, 0, 0.05);
}

.checkbox-field input + .checkbox-icon svg {
  display: none;
}

.checkbox-field input:checked + .checkbox-icon {
  background: rgb(27, 99, 225);
}

.checkbox-field input:checked + .checkbox-icon svg {
  display: block;
  fill: white;
}

h1 {
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  color: #000000;
  margin-top: 20px;
}

p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16.5px;
  line-height: 25px;
}

.monospace {
  font-family: 'Mono', 'Menlo', monospace;
}

#root {
  height: 100%;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 10px;
  width: 300px;
  z-index: 20;
  flex-direction: row-reverse;
}

.dropdown-title {
  margin: 0;
  font-size: 14.5px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
  display: inline-block;
  white-space: nowrap;
  padding-bottom: 10px;
}

.dropdown-subtitle {
  font-size: 15px;
  line-height: 20px;
  color: rgb(0, 0, 0);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
  white-space: nowrap;
}

.dropdown-action {
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dropdown-inner {
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.dropdown-trigger {
  outline: none;
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.dropdown-trigger.button-light {
  border-radius: 40px;
  width: 60px;
  height: 60px;
}

.dropdown-trigger svg {
  flex: none;
}

.settings-trigger.trigger-open {
  background: rgba(0, 0, 0, 0.05);
}

.settings-trigger.trigger-open svg {
  fill: rgba(0, 0, 0, 1);
}

.settings-trigger svg {
  margin-left: -1px;
  transition: fill 0.1s ease;
  fill: rgba(0, 0, 0, 0.5);
}

.settings-trigger:hover svg,
.settings-trigger:focus svg {
  fill: rgba(0, 0, 0, 1);
}

.settings-trigger-hidden svg {
  fill: rgba(0, 0, 0, 0);
}

.settings-trigger-hidden:hover {
  background-color: rgba(255, 255, 255, 0.5)!important;
}

.settings-trigger-hidden:hover svg,
.settings-trigger-hidden:focus svg {
  fill: rgba(0, 0, 0, 1);
}

button {
  padding: 10px;
  border: 0;
  cursor: pointer;
  background: transparent;
  font-size: 16.5px;
  line-height: 25px;
  font-weight: bold;
  border-radius: 5px;
  padding: 7px 15px;
}

.button-light {
  background: transparent;
  color: #1e6efa;
}

.button-small {
  font-size: 15px;
  line-height: 20px;
  padding: 6px 15px;
}

.button-light.button-bordered {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.button-dark {
  background-color: #3264f0;
  color: #ffffff;
}

.button-light:hover,
.button-light:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.button-light:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.button-dark:hover,
.button-dark:focus {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 100px 100px inset;
}

.button-dark:active {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 100px 100px inset;
}

.button-dark-spaced {
  margin-bottom: 20px;
}

.dropdown-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  height: 40px;
  align-items: flex-start;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-item.horizontal {
  flex-flow: row wrap;
  height: auto;
}

.dropdown-item.grouped {
  height: auto;
  display: block;
  padding: 20px;
}

.dropdown-item-row {
  display: flex;
  flex-flow: row nowrap;
  height: auto;
  width: 100%;
  padding: 10px 0;
}

.dropdown-item-row:last-child {
  padding-bottom: 0;
}

.dropdown-item-row:first-of-type {
  padding-top: 0;
}

.dropdown-item-row button {
  margin-left: -5px;
  flex-grow: 1;
}

.dropdown-item:first-child {
  border: 0;
}

#badge {
  background: red;
  color: white;
  border-radius: 30px;
  padding: 5px 6px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  position: fixed;
  bottom: 60px;
  right: 18px;
  z-index: 10;
  min-width: 10px;
  display: none;
}

.settings-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.settings-inner {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  flex-shrink: 0;
  flex-grow: 1;
}

.settings-form {
  width: 100%;
  max-width: 400px;
}

.settings-group-wrapper {
  padding: 10px 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-bottom: 20px;
}

.settings-group-title {
  text-align: center;
}

.settings-inner .button-dark {
  width: 100%;
}

.settings-inner .button-light {
  margin-top: 20px;
}

.icon-contain {
  width: 60px;
  height: 60px;
  border-radius: 999px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.settings-subtitle {
  margin: 20px 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16.5px;
  line-height: 25px;
  margin-top: 20px;
}

/* Shell and beta app */
.selector {
  background: white;
  flex-direction: row;
  height: auto;
}

.selector-title {
  color: black;
  font-size: 16.5px;
  line-height: 20px;
  flex-shrink: 0;
}

.selector-toggle {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin: 10px 0;
  padding: 10px 20px;
  user-select: none;
  transition: background 0.1s ease;
}

.selector-toggle:hover,
.selector-toggle:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.selector-toggle:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.selector.open .selector-title {
  font-size: 14.5px;
  color: rgba(0, 0, 0, 0.5);
}

.selector-value {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16.5px;
  line-height: 20px;
  flex-grow: 1;
  text-align: right;
  margin-right: 5px;
  margin-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selector-value.open {
  color: transparent;
}

.selector ul {
  color: black;
  width: 100%;
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: -10px 0 10px;
}

.selector ul li {
  padding: 0;
  margin: 0;
  font-size: 16.5px;
  line-height: 20px;
  transition: background 0.1s ease;
  position: relative;
}

.selector ul li:after {
  transition: left 0.1s ease;
  background: #1e6efa;
  content: '';
  position: absolute;
  height: 40px;
  width: 3px;
  left: -3px;
  top: 0;
}

.selector ul li.selected:after {
  left: 0;
}

.selector ul li a {
  color: black;
  display: flex;
  padding: 10px 20px;
}

.selector ul li a:hover,
.selector ul li a:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.selector ul li a:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.selector ul li.selected a {
  color: #1e6efa;
}

.landing {
  display: flex;
  text-align: center;
  max-width: 500px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
  min-width: 100%;
  box-sizing: border-box;
  z-index: 1;
  position: fixed;
  flex-direction: column;
  overflow: auto;
}

.mobile-landing {
  background-color: var(--atomic-dark-blue);
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  margin: 0 auto;
  height: 100%;
  min-width: 100%;
  box-sizing: border-box;
  z-index: 1;
  position: fixed;
  overflow: auto;
}

.mobile-logo {
  max-height: 30px;
  padding: 35px;
}

.mobile-heading {
  font-size: 21px;
  margin: 0;
}

.card {
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
}

.card ul {
  padding-left: 30px;
  margin: 20px 0;
}

.card li {
  list-style-image: url('./assets/check-icon.svg');
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 8px;
}

.button-positive {
  padding: 15px;
  width: 100%;
  max-width: 330px;
  display: block;
  margin: 0 auto 15px;
  background-color: var(--atomic-light-blue);
  color: #ffffff;
  text-align: center;
}

.button-negative {
  padding: 15px;
  width: 100%;
  max-width: 330px;
  display: block;
  margin: 0 auto;
  background-color: var(--atomic-light-grey);
  color: var(--atomic-text-blue);
  text-align: center;
}

.landing .button-light {
  margin-top: 20px;
  flex-shrink: 0;
}

.layout-margin {
  min-height: 120px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.headless-wrapper {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 500px;
}

.headless-buttons {
  padding: 5px 0;
}

.headless-buttons :not(:last-child) {
  margin-right: 5px;
}

.headless-card {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid black;
  text-align: center;
}

.headless-card > button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

@media (max-width: 600px) {
  .layout-margin {
    min-height: 60px;
  }
}

.landing p {
  /* Required for IE */
  width: 100%;
  margin: 20px 0;
  max-width: 500px;
  flex-shrink: 0;
}

.landing .button-dark {
  flex-shrink: 0;
}

.landing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 100px 0;
}

.landing .magic-icon {
  flex-shrink: 0;
}

.full-logo {
  align-items: center;
}

/* SDK styles */
#action-cards-sdk-launcher-wrapper #action-cards-sdk-launcher,
.atomic-sdk-launcher-wrapper .atomic-sdk-launcher {
  background-color: black;
}

#embed {
  position: absolute;
  width: 420px;
  top: 80px;
  right: 0;
  height: calc(100vh - 80px);
  overflow: auto;
}

@media (max-width: 420px) {
  #embed {
    width: 100%;
  }
}

#embed iframe.open {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#embed iframe, #horizontal-embed iframe {
  width: 100%;
  height: 100%;
}

#embed iframe.single-card {
  margin: 10px;
  width: calc(100% - 20px);
}

#embed iframe.single-card.has-card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
}

#embed iframe.modal-subview, #horizontal-embed iframe.modal-subview {
  position: fixed;
  width: 500px;
  max-height: 60%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  opacity: 0;
  transition: 0.3s opacity ease;
  pointer-events: none;
}

#embed iframe.modal-subview.has-subview, #horizontal-embed iframe.modal-subview.has-subview {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 600px) {
  #embed iframe.modal-subview.has-subview, #horizontal-embed iframe.modal-subview.has-subview {
    max-width: 100%;
  }
}

#horizontal-embed {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  overflow: auto;
}
